import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { styled } from "@mui/material/styles";
import { Link, useGetList } from "react-admin";
import DashboardCard from "./DashboardCard";
import MonthlyPerformanceTable from "./MonthlyPerformanceTable";
import RevenuePerCustomerDataGrid from "./RevenuePerCustomerDataGrid";

const StyledPaper = styled(Paper)({
    display: "flex",
    flexDirection: "column",
    padding: "10px 15px",
    borderRadius: "10px",
    margin: "5px",
});
const loggedInUser = localStorage.getItem("admin");
const parsedUser = JSON.parse(loggedInUser);
const loggedInUserID = parsedUser ? parsedUser.id : null;

export const RevenuePerCustomerWidget = ({ isAdmin, users }) => {
    const [customerTypeFilter, setCustomerTypeFilter] = useState("all");
    const [selectedUser, setSelectedUser] = useState(null);
    const [filter, setFilter] = useState({
        account_manager_id: loggedInUserID,
        customerType: "all",
    });

    useEffect(() => {
        // When the customerTypeFilter changes, update the filter state
        setFilter((prevFilter) => ({
            ...prevFilter,
            customerType: customerTypeFilter,
        }));
    }, [customerTypeFilter]);
    const handleUserChange = (newUserId) => {
        // console.log({ selectedUser });
        setSelectedUser(newUserId);
        setFilter((prevFilter) => ({
            ...prevFilter,
            account_manager_id: newUserId ? newUserId : loggedInUserID,
        }));
    };
    return (
        <StyledPaper>
            <Stack>
                <Box display={"flex"} flexWrap={"wrap-reverse"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h4" sx={{ p: 1, fontSize: "1.3rem" }}>
                      Umsatz pro Kunde 
                    {/* Revenue Per Customer Table */}
                    </Typography>
                    <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                        {isAdmin && (
                            <TextField select sx={{ minWidth: 120 }} label="Kundenbetreuer" value={selectedUser} onChange={(event) => handleUserChange(event.target.value)} size="small">
                                <MenuItem key={0} value="none">
                                    None
                                </MenuItem>
                                {users.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        <TextField select sx={{ minWidth: 120 }} label="Kunden" value={customerTypeFilter} onChange={(event) => setCustomerTypeFilter(event.target.value)} size="small">
                            <MenuItem value="all">All Kunden</MenuItem>
                            <MenuItem value="activeOnly">Active Only</MenuItem>
                        </TextField>
                    </Box>
                </Box>
                {isAdmin && selectedUser == null ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <p style={{ margin: "50px", color: "gray" }}>Select any account manager to show results...</p>
                    </div>
                ) : (
                    <RevenuePerCustomerDataGrid filter={filter} />
                )}
            </Stack>
        </StyledPaper>
    );
};
export const MonthlyPerformanceWidget = ({ isAdmin, users }) => {
    const [columnToFilterFrom, setColumnToFilterFrom] = useState("auftragseingang");
    const [selectedUser, setSelectedUser] = useState(users ? users[0].id : null);
    const [filter, setFilter] = useState({
        account_manager_id: isAdmin ? selectedUser : loggedInUserID,
        column_to_filter_from: "auftragseingang",
    });
    // console.log(filter.account_manager_id);

    useEffect(() => {
        setFilter((prevFilter) => ({
            ...prevFilter,
            column_to_filter_from: columnToFilterFrom,
        }));
    }, [columnToFilterFrom]);
    const handleUserChange = (selectedUser) => {
        setSelectedUser(selectedUser);
        setFilter((prevFilter) => ({
            ...prevFilter,
            account_manager_id: selectedUser ? selectedUser : loggedInUserID,
        }));
    };

    return (
        <StyledPaper>
            <Stack>
                <Box display={"flex"} flexWrap={"wrap-reverse"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h4" sx={{ p: 1, fontSize: "1.5rem" }}>
                       Monatliche Performance
                    </Typography>
                    <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                        {isAdmin && (
                            <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                                <TextField select sx={{ minWidth: 120 }} label="Kundenbetreuer" value={selectedUser} onChange={(event) => handleUserChange(event.target.value)} size="small">
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        )}
                        <Box display={"flex"} flexWrap={"wrap"} gap={1}>
                            <TextField select sx={{ minWidth: 120 }} label="Ansicht nach" value={columnToFilterFrom} onChange={(event) => setColumnToFilterFrom(event.target.value)} size="small">
                                <MenuItem value="auftragseingang">Auftragseingang</MenuItem>
                                <MenuItem value="finish_date">Fertigstellung</MenuItem>
                            </TextField>
                        </Box>
                    </Box>
                </Box>
                <MonthlyPerformanceTable filter={filter} />
            </Stack>
        </StyledPaper>
    );
};
export const NeukundenRegion = ({ regions, isUserDashboard, firstRegionId }) => {
  const [selectedRegion, setSelectedRegion] = useState(
    isUserDashboard ? firstRegionId : "all"
  );
  const { data, isLoading } = useGetList("dashboardCards&action=neukunden", {
    filter:
    selectedRegion && selectedRegion !== "all"
    ? { regionId: selectedRegion, isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID}
    : {isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID, dashUserRegionId: selectedRegion, },
  });
  // if(!isLoading){
  //   console.log("🚀 ~ NeukundenRegion ~ data:", data)
  // }
  if(!isLoading && !data){
    return;
  }
  if(isUserDashboard){
    if(regions.length < 1){
      return;
    } 
  }
  return (
      <StyledPaper>
        <Stack>
          <Stack
            direction="row"
            mb={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h4" sx={{ px: 1, fontSize: "1.5rem" }}>
              Neukunden
            </Typography>
            <TextField
              select
              sx={{ minWidth: 150 }}
              label="Regions"
              value={selectedRegion}
              onChange={(event) => setSelectedRegion(event.target.value)}
            >
              {
                !isUserDashboard && <MenuItem key={0} value="all"> All Regions </MenuItem>
              }
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          {!isLoading ? (
            <Grid key={4} container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DashboardCard
                  name="Dieses Jahr"
                  trackingValue={parseInt(data[0].thisYear)}
                  defaultGrid={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DashboardCard
                  name="Dieser Monat"
                  trackingValue={parseInt(data[0].thisMonth)}
                  defaultGrid={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DashboardCard
                  name="Letzter Monat"
                  trackingValue={parseInt(data[0].lastMonth)}
                  defaultGrid={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DashboardCard
                  name="Durchschnitt letzte 6 Monate"
                  trackingValue={parseInt(data[0].avg)}
                  defaultGrid={false}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              height={300}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          )}
        </Stack>
      </StyledPaper>
  );
};
export const UmsatzRegion = ({ regions, title, isUserDashboard, firstRegionId }) => {
  const [selectedRegion, setSelectedRegion] =  useState(
    isUserDashboard ? firstRegionId : "all"
  );
  const { data, isLoading } = useGetList(`dashboardCards&action=` + title, {
    filter:
      selectedRegion && selectedRegion !== "all"
        ? { regionId: selectedRegion, isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID }
        : {isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID, dashUserRegionId: selectedRegion, },
  });
  if(!isLoading && !data){
    return;
  }
  if(isUserDashboard){
    if(regions.length < 1){
      return;
    }
  }
  return (
      <StyledPaper>
        <Stack>
          <Stack
            direction="row"
            mb={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h4" sx={{ px: 1, fontSize: "1.5rem" }}>
              {title === "umsatz" && "Umsatz"}
              {title === "umsatzFertiggestellt" && "Umsatz Fertiggestellt"}
            </Typography>
            <TextField
              select
              sx={{ minWidth: 150 }}
              label="Regions"
              value={selectedRegion}
              onChange={(event) => setSelectedRegion(event.target.value)}
            >
              {
                !isUserDashboard && <MenuItem key={0} value="all"> All Regions </MenuItem>
              }
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          {!isLoading && data[0] ? (
            <Grid key={4} container spacing={"auto"}>
              <Grid item xs={12} sm={6}>
                <DashboardCard
                  name="Heute"
                  trackingValue={parseInt(data[0].todayUmsatz)}
                  tracking2Value={parseInt(data[0].todayDeckungsbeitrag)}
                  percent={data[0].todayPercent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DashboardCard
                  name="Dieser Monat"
                  trackingValue={parseInt(data[0].thisMonthUmsatz)}
                  tracking2Value={parseInt(data[0].thisMonthDeckungsbeitrag)}
                  percent={data[0].thisMonthPercent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DashboardCard
                  name="Letzter Monat"
                  trackingValue={parseInt(data[0].lastMonthUmsatz)}
                  tracking2Value={parseInt(data[0].lastMonthDeckungsbeitrag)}
                  percent={data[0].lastMonthPercent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DashboardCard
                  name="Letzte 6 Monate"
                  trackingValue={parseInt(data[0].lastSixMonthsUmsatz)}
                  tracking2Value={parseInt(data[0].lastSixMonthsDeckungsbeitrag)}
                  percent={data[0].lastSixMonthsPercent}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DashboardCard
                  name="Durchschnitt 6 Monate"
                  trackingValue={parseFloat(data[0].avgUmsatz)}
                  tracking2Value={parseFloat(data[0].avgDeckungsbeitrag)}
                  percent={data[0].avgPercent}
                />
              </Grid>
            </Grid>
          ) : (
            <Box
              height={300}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          )}
        </Stack>
      </StyledPaper>
  );
};
export const TopKunden = ({ regions, isUserDashboard, firstRegionId }) => {
  const [selectedRegion, setSelectedRegion] = useState(
    isUserDashboard ? firstRegionId : "all"
  );
  const [customerTypeFilter, setCustomerTypeFilter] = useState("all");
  const [selectedTime, setSelectedTime] = useState("all");
  const filters = {
    ...(selectedRegion && selectedRegion !== "all" 
      ? { regionId: selectedRegion, isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID, customerTypeFilter } 
      : {isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID, dashUserRegionId: selectedRegion, customerTypeFilter}),
    ...(selectedTime && selectedTime !== "all" ? { timeRange: selectedTime } : {getTimeRangeForAllCustomers: true}),
  };

  const { data, isLoading } = useGetList("dashboardCards&action=topKunden", {
    filter: filters,
  });
  let selectedTimeRange;
  switch(selectedTime){
    case "thisMonth":
      selectedTimeRange = 1;
    break;
    case "lastMonth":
      selectedTimeRange = 1;
    break;
    case "lastQuater":
      selectedTimeRange = 3;
    break;
    case "lastSixMonths":
      selectedTimeRange = 6;
    break;
    case "lastYear":
      selectedTimeRange = 12;
    break;
    default: selectedTimeRange = 1
  }
  if (isLoading) {
    return (
      <Paper sx={{ padding: 2 }}> 
         <Stack>
        {/* Filters Section */}
        <Box display="flex" flexWrap="wrap-reverse" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" sx={{ p: 1, fontSize: "1.5rem" }}>
            {selectedTime === "all" ? "All-Time Top-Kunden" : "Top-Kunden"}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Kunden"
              value={customerTypeFilter}
              onChange={(event) => setCustomerTypeFilter(event.target.value)}
              size="small"
            >
              <MenuItem value="all">All Kunden</MenuItem>
              <MenuItem value="myCustomers">My Customers</MenuItem>
            </TextField>

            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Time"
              value={selectedTime}
              onChange={(event) => setSelectedTime(event.target.value)}
              size="small"
            >
              <MenuItem value="all">All Time</MenuItem>
              <MenuItem value="thisMonth">Dieser Monat</MenuItem>
              <MenuItem value="lastMonth">Letzter Monat</MenuItem>
              <MenuItem value="lastQuater">Letzte 3 Monate</MenuItem>
              <MenuItem value="lastSixMonths">Letzte 6 Monate</MenuItem>
              <MenuItem value="lastYear">Letzte 12 Monate</MenuItem>
            </TextField>

            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Regions"
              value={selectedRegion}
              onChange={(event) => setSelectedRegion(event.target.value)}
              size="small"
            >
              {!isUserDashboard && <MenuItem value="all">All Regions</MenuItem>}
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>

        {/* Table Section */}
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell width="85px">Ranking</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right" width="100px">Jobs</TableCell>
                <TableCell align="right">Revenue</TableCell>
                <TableCell align="right">Anzahl Einheiten</TableCell>
                <TableCell align="right">Umsatzpotential</TableCell>
                <TableCell align="right">Wallet share</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7} sx={{ p: 2 }}>
                    <Box height={100} display="flex" justifyContent="center" alignItems="center">
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Stack>
        {/* Filters Section */}
        <Box display="flex" flexWrap="wrap-reverse" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" sx={{ p: 1, fontSize: "1.5rem" }}>
            {selectedTime === "all" ? "All-Time Top-Kunden" : "Top-Kunden"}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Kunden"
              value={customerTypeFilter}
              onChange={(event) => setCustomerTypeFilter(event.target.value)}
              size="small"
            >
              <MenuItem value="all">All Kunden</MenuItem>
              <MenuItem value="myCustomers">My Customers</MenuItem>
            </TextField>

            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Time"
              value={selectedTime}
              onChange={(event) => setSelectedTime(event.target.value)}
              size="small"
            >
              <MenuItem value="all">All Time</MenuItem>
              <MenuItem value="thisMonth">Dieser Monat</MenuItem>
              <MenuItem value="lastMonth">Letzter Monat</MenuItem>
              <MenuItem value="lastQuater">Letzte 3 Monate</MenuItem>
              <MenuItem value="lastSixMonths">Letzte 6 Monate</MenuItem>
              <MenuItem value="lastYear">Letzte 12 Monate</MenuItem>
            </TextField>

            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Regions"
              value={selectedRegion}
              onChange={(event) => setSelectedRegion(event.target.value)}
              size="small"
            >
              {!isUserDashboard && <MenuItem value="all">All Regions</MenuItem>}
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>

        {/* Table Section */}
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell width="85px">Ranking</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right" width="100px">Jobs</TableCell>
                <TableCell align="right">Revenue</TableCell>
                <TableCell align="right">Anzahl Einheiten</TableCell>
                <TableCell align="right">Umsatzpotential</TableCell>
                <TableCell align="right">Wallet share</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7} sx={{ p: 2 }}>
                    {isUserDashboard && regions.length === 0 ? 'No Region Access' : 'No Record Found'} 
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
    );
  }
  return (
    <Paper sx={{ padding: 2 }}>
      <Stack>
        {/* Filters Section */}
        <Box display="flex" flexWrap="wrap-reverse" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" sx={{ p: 1, fontSize: "1.5rem" }}>
            {selectedTime === "all" ? "All-Time Top-Kunden" : "Top-Kunden"}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Kunden"
              value={customerTypeFilter}
              onChange={(event) => setCustomerTypeFilter(event.target.value)}
              size="small"
            >
              <MenuItem value="all">All Kunden</MenuItem>
              <MenuItem value="myCustomers">My Customers</MenuItem>
            </TextField>

            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Time"
              value={selectedTime}
              onChange={(event) => setSelectedTime(event.target.value)}
              size="small"
            >
              <MenuItem value="all">All Time</MenuItem>
              <MenuItem value="thisMonth">Dieser Monat</MenuItem>
              <MenuItem value="lastMonth">Letzter Monat</MenuItem>
              <MenuItem value="lastQuater">Letzte 3 Monate</MenuItem>
              <MenuItem value="lastSixMonths">Letzte 6 Monate</MenuItem>
              <MenuItem value="lastYear">Letzte 12 Monate</MenuItem>
            </TextField>

            <TextField
              select
              sx={{ minWidth: 120 }}
              label="Regions"
              value={selectedRegion}
              onChange={(event) => setSelectedRegion(event.target.value)}
              size="small"
            >
              {!isUserDashboard && <MenuItem value="all">All Regions</MenuItem>}
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>

        {/* Table Section */}
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell width="85px">Ranking</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right" width="100px">Jobs</TableCell>
                <TableCell align="right">Revenue</TableCell>
                <TableCell align="right">Anzahl Einheiten</TableCell>
                <TableCell align="right">Umsatzpotential</TableCell>
                <TableCell align="right">Wallet share</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                if (selectedTime === "all") {
                  selectedTimeRange = row.total_months_since;
                }
                const customer_potential = row.units_under_management ? 5 * row.units_under_management * selectedTimeRange : 0;
                const wallet_share = customer_potential > 0 ? ((row.revenue / customer_potential) * 100).toFixed(1) : "0.0";

                return (
                  <TableRow key={row.id}>
                    <TableCell align="center">
                      {row.ranking === "1" && <EmojiEventsIcon style={{ color: "gold" }} />}
                      {row.ranking === "2" && <EmojiEventsIcon style={{ color: "#C0C0C0" }} />}
                      {row.ranking === "3" && <EmojiEventsIcon style={{ color: "#b08d57" }} />}
                      {row.ranking !== "1" && row.ranking !== "2" && row.ranking !== "3" && row.ranking}
                    </TableCell>
                    <TableCell>
                      <Link to={`/kundenlistes/${row.id}`} style={{ textDecoration: "none" }}>{row.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.jobs}</TableCell>
                    <TableCell align="right">{row.revenue}</TableCell>
                    <TableCell align="right">{row.units_under_management || <small style={{ color: "lightgray" }}>N/A</small>}</TableCell>
                    <TableCell align="right">{customer_potential}</TableCell>
                    <TableCell align="right">{wallet_share}%</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Paper>
  );
};
export const NochNichtAbgerechnet = ({ regions, isUserDashboard, firstRegionId }) => {
  const [selectedRegion, setSelectedRegion] =  useState(
    isUserDashboard ? firstRegionId : "all"
  );
  const { data, isLoading } = useGetList(
    "dashboardCards&action=nochNichtAbgerechnet",
    {
      filter:
        selectedRegion && selectedRegion !== "all"
          ? { regionId: selectedRegion, isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID }
          : {isUserDashboard: isUserDashboard, userIdForRegion: loggedInUserID, dashUserRegionId: selectedRegion,},
    }
  );
  if(!isLoading && !data){
    return;
  }
  if(isUserDashboard){
    if(regions.length < 1){
      return;
    } 
  }
  return (
    <StyledPaper>
      <Stack>
        <Box
          mb={2}
          gap={2}
          display={"flex"}
          flexWrap={"wrap-reverse"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4" sx={{ px: 1, fontSize: "1.5rem" }}>
            Noch nicht abgerechnet
          </Typography>
          <TextField
            select
            sx={{ minWidth: 150 }}
            label="Regions"
            value={selectedRegion}
            onChange={(event) => setSelectedRegion(event.target.value)}
          >
              {
                !isUserDashboard && <MenuItem key={0} value="all"> All Regions </MenuItem>
              }
            {regions.map((region) => (
              <MenuItem key={region.id} value={region.id}>
                {region.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {!isLoading ? (
          <Grid key={4} container spacing={2}>
            <Grid item xs={12} sm={6} lg={12}>
              <DashboardCard
                name="Anzahl"
                trackingValue={parseInt(data[0].totalProjects)}
                defaultGrid={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
              <DashboardCard
                name="Umsatz"
                trackingValue={parseInt(data[0].revenue)}
                defaultGrid={false}
              />
              {/* <DashboardCard name="Umsatz" trackingValue={parseInt(data[0].revenue)} tracking2Value={parseInt(data[0].deckungsbeitrag)} percent={parseInt(data[0].percent)}  /> */}
            </Grid>
          </Grid>
        ) : (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </StyledPaper>
  );
};
export const Worklist = ({ regions }) => {
  const { data, isLoading } = useGetList("dashboardCards&action=worklist");
  return (
    <StyledPaper>
      <Stack>
        <Box
          display={"flex"}
          flexWrap={"wrap-reverse"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h4" sx={{ p: 1, fontSize: "1.5rem" }}>
            Worklist
          </Typography>
        </Box>
        {!isLoading ? (
          <Box sx={{ minHeight: 300 }}>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right" width={"100px"}>
                      Red
                    </TableCell>
                    <TableCell align="right" width={"100px"}>
                      Yellow
                    </TableCell>
                    <TableCell align="right" width={"100px"}>
                      Pink
                    </TableCell>
                    <TableCell align="right" width={"100px"}>
                      Green
                    </TableCell>
                    <TableCell align="right">Unfinished Tasks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 && (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        textAlign: "center",
                      }}
                    >
                      <TableCell align="center" colSpan={5}>
                        No Record Found
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Link
                          to={`/tasks?filter={"workId":"${row.id}"}`}
                          style={{ textDecoration: "none" }}
                        >
                          {row.name}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{row.red}</TableCell>
                      <TableCell align="right">{row.yellow}</TableCell>
                      <TableCell align="right">{row.pink}</TableCell>
                      <TableCell align="right">{row.green}</TableCell>
                      <TableCell align="right">{row.notFinished}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Box
            height={300}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </StyledPaper>
  );
};
