import * as React from "react";
import { Admin, Resource, defaultTheme } from "react-admin";
import { UserList, UserEdit, UserCreate } from "./components/Users";
import { WorkerList, WorkerEdit, WorkerCreate } from "./components/Workers";
import { CtypeList, CtypeEdit, CtypeCreate } from "./components/Ctypes";
import { RegionList, RegionEdit, RegionCreate } from "./components/Regions";
import { ProjectList, ProjectEdit, ProjectCreate } from "./components/Projects";

import {
  KontaktdatenList,
  KontaktdatenEdit,
  KontaktdatenCreate,
} from "./components/Kontaktdatens";
import {
  KundenlisteList,
  KundenlisteEdit,
  KundenlisteCreate,
} from "./components/Kundenlistes";
import { InactiveKundenlisteList } from "./components/InactiveKundenlistes";
import { WorkList, WorkEdit, WorkCreate } from "./components/Works";
import { TaskList, TaskEdit, TaskCreate } from "./components/Tasks";

import { TrackList } from "./components/Tracks";
import { ReportList } from "./components/Reports";
import { FertiggestelltList } from "./components/fertiggestellt";
import { KostenList, KostenCreate, KostenEdit } from "./components/Kosten";
import Dashboard from "./components/Dashboard";
import MyLayout from "./layout/MyLayout";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";

import jsonServerProvider from "ra-data-json-server";
import SchoolIcon from "@mui/icons-material/School";
import UserIcon from "@mui/icons-material/Group";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { PerformBewertungList } from "./components/PerformBewertung";
import { LeaderbordsList } from "./components/Leaderbords";
import { IntranetList } from "./components/Intranet";
import { ErrorLinkEdit, ErrorLinkList } from "./components/ErrorLinks";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import CustomLoginPage from "./components/CustomLoginPage";

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiTooltip: {
      styleOverrides: {
          tooltip: {
              fontSize: "14px",
          },
      },
  },
  },
};

const dataProviderData = jsonServerProvider(
  "https://jsonplaceholder.typicode.com"
);

const App = () => (
  <Admin
    theme={theme}
    layout={MyLayout}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={CustomLoginPage}
  >
    {(permissions) => {
      return (
        <>
          <Resource
            name="regions"
            list={RegionList}
            recordRepresentation="name"
            create={
              permissions.access === "super" || permissions.access === "admin"
                ? RegionCreate
                : null
            }
            icon={FmdGoodIcon}
            edit={
              permissions.access === "super" || permissions.access === "admin"
                ? RegionEdit
                : null
            }
            options={{ label: "Standorte" }}
          />
          {permissions.access === "super" || permissions.access === "admin" ? (
            <Resource
              name="users"
              list={UserList}
              recordRepresentation="name"
              create={UserCreate}
              icon={UserIcon}
              edit={UserEdit}
              options={{ label: "Benutzer" }}
            />
          ) : null}

          <Resource
            name="projects"
            list={ProjectList}
            create={ProjectCreate}
            recordRepresentation="adresse"
            icon={ApartmentIcon}
            edit={ProjectEdit}
            options={{ label: "Projekte" }}
          />
          {permissions.access === "super" || permissions.access === "admin" ? (
            <Resource
              name="workers"
              list={WorkerList}
              recordRepresentation="name"
              create={WorkerCreate}
              icon={EngineeringIcon}
              edit={WorkerEdit}
              options={{ label: "Zuständig" }}
            />
          ) : null}
          {permissions.access === "super" ||
          permissions.reportRegionsExist === true ? (
            <>
              <Resource
                name="reports"
                list={ReportList}
                icon={AssessmentIcon}
                options={{ label: "Umsätze" }}
              />
              <Resource
                name="fertiggestellt"
                list={FertiggestelltList}
                icon={AssessmentIcon}
                options={{ label: "Umsätze Fertiggestellt" }}
              />
            </>
          ) : null}
          {permissions.access === "super" ? (
            <>
              <Resource
                name="kostens"
                list={KostenList}
                create={permissions.access === "super" ? KostenCreate : null}
                icon={PriceChangeIcon}
                edit={permissions.access === "super" ? KostenEdit : null}
                options={{ label: "Kosten" }}
              />
              <Resource
                name="ctypes"
                list={CtypeList}
                recordRepresentation="name"
                create={CtypeCreate}
                icon={MonetizationOnIcon}
                edit={CtypeEdit}
                options={{ label: "Cost Type" }}
              />
              <Resource
                name="tracks"
                list={TrackList}
                icon={GpsFixedIcon}
                options={{ label: "Tracking" }}
              />
            </>
          ) : null}
          {/* <Resource
          name="kontaktdatens"
          list={KontaktdatenList}
          recordRepresentation="name"
          create={permissions.access === "super" ? KontaktdatenCreate : null}
          icon={UserIcon}
          edit={permissions.access === "super" ? KontaktdatenEdit : null}
          options={{ label: "Kontaktdaten" }}
        /> */}
          <Resource
            name="kontaktdatens"
            list={KontaktdatenList}
            recordRepresentation="name"
            create={KontaktdatenCreate}
            icon={UserIcon}
            edit={KontaktdatenEdit}
            options={{ label: "Kontaktdaten" }}
          />
          <Resource
            name="kundenlistes"
            list={KundenlisteList}
            recordRepresentation="name"
            create={KundenlisteCreate}
            icon={UserIcon}
            edit={KundenlisteEdit}
            options={{ label: "Kundenliste" }}
          />
          <Resource
            name="works"
            list={WorkList}
            recordRepresentation="name"
            create={WorkCreate}
            icon={FormatListNumberedIcon}
            edit={WorkEdit}
            options={{ label: "Worklists" }}
          />
          <Resource
            name="tasks"
            list={TaskList}
            recordRepresentation="name"
            create={TaskCreate}
            icon={PlaylistAddCheckIcon}
            edit={TaskEdit}
            options={{ label: "Aufgaben" }}
          />
          <Resource
            name="inactivekundenlistes"
            list={InactiveKundenlisteList}
            recordRepresentation="name"
            icon={UserIcon}
            options={{ label: "Inaktive Kunden" }}
          />
          {permissions.access === "super" ? (
            <Resource
              name="leaderbords"
              list={LeaderbordsList}
              recordRepresentation="name"
              icon={UserIcon}
              options={{ label: "Leaderbords" }}
            />
          ) : null}
          {permissions.access === "super" ||
          permissions.access === "admin" ||
          permissions.show_performance_evaluation ? (
            <Resource
              name="performancebewertung"
              list={PerformBewertungList}
              options={{ label: "Performance Bewertung" }}
            />
          ) : null}
          <Resource
            name="intranet"
            list={IntranetList}
            icon={SchoolIcon}
            options={{ label: "Intranet" }}
          />
          {permissions.access === "super" ? (
            <Resource
              name="errorlink"
              list={ErrorLinkList}
              edit={ErrorLinkEdit}
              options={{ label: "Error Link" }}
              icon={ErrorRoundedIcon}
            />
          ) : null}
        </>
      );
    }}
  </Admin>
);

export default App;
