import * as React from "react";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import logo from "../images/logo.png";
import { useGetList, usePermissions } from "react-admin";
import {
  NeukundenRegion,
  NochNichtAbgerechnet,
  TopKunden,
  UmsatzRegion,
  Worklist,
  MonthlyPerformanceWidget,
  RevenuePerCustomerWidget,
} from "./DashboardCards/AllCards";

const Dashboard = () => {
  const loggedInUser = localStorage.getItem("admin");
  const loggedInUserId = loggedInUser ? JSON.parse(loggedInUser).id : null;
  const { permissions } = usePermissions();
  const { data: regionsData, isLoading: loadingRegions } = useGetList(
    "regions",
    { filter: { getCustomRegionsOnly: true } }
  );
  const { data: userRegionsData, isLoading: loadingUserRegions } = useGetList(
    "regions",
    { filter: { getCustomRegionsOnly: true , getDashAccUserRegions: true, loggedInUserId: loggedInUserId} }
  );
  const { data: UmsatzeAndFertiggestellteRegionsData, isLoading: loadingUmsatzeAndFertiggestellteRegions} = useGetList(
    "regions",
    {filter: { getCustomRegionsOnly: true, getUmsatzeAndFertiggestellteRegionsData: true, loggedInUserId: loggedInUserId } }
  );
  const { data: usersData, isLoading: loadingUsers } = useGetList(
    "users"
  );
  if (permissions.access === "super") {
    return (
      <Card>
        <CardHeader
          title="Herzlich willkommen zurück!"
          sx={{ "& .MuiCardHeader-title": { fontSize: "2.5rem" } }}
          style={{ textAlign: "center" }}
        />
        <CardContent sx={{ backgroundColor: "#f4f4f4" }}>
          {!loadingRegions && (
            <Grid key={2} container spacing={2} sx={{ px: 3, pb: 4, pt: 3 }}>
              <Grid item xs={12}>
                <NeukundenRegion regions={regionsData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <UmsatzRegion regions={regionsData} title={"umsatz"} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <UmsatzRegion
                  regions={regionsData}
                  title={"umsatzFertiggestellt"}
                />
              </Grid>
              {
                !loadingUmsatzeAndFertiggestellteRegions && 
                <Grid item xs={12} lg={12}>
                  <TopKunden regions={UmsatzeAndFertiggestellteRegionsData} />
                </Grid>
              }
              <Grid item xs={12} lg={8}>
                <Worklist />
              </Grid>
              <Grid item xs={12} lg={4}>
                <NochNichtAbgerechnet regions={regionsData} />
              </Grid>
              {!loadingUsers && (
                <Grid item xs={12} lg={12}>
                  <MonthlyPerformanceWidget isAdmin="true" users={usersData} />
                </Grid>
              )}
              {!loadingUsers && (
                <Grid item xs={12} lg={12}>
                  <RevenuePerCustomerWidget isAdmin="true" users={usersData} />
                </Grid>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card style={{ textAlign: "center" }}>
        <CardHeader
          title="Herzlich willkommen zurück!"
          sx={{ "& .MuiCardHeader-title": { fontSize: "2.5rem" } }}
        />
        <CardContent sx={{ backgroundColor: "#f4f4f4" }}>
          {!loadingUserRegions && (
            <Grid key={2} container spacing={2} sx={{ px: 3, pb: 4, pt: 3 }}>
              <Grid item xs={12}>
                <NeukundenRegion 
                  regions={userRegionsData} 
                  isUserDashboard={true} 
                  firstRegionId={
                    (userRegionsData && userRegionsData.length > 0) ? userRegionsData[0].id : null
                  } 
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <UmsatzRegion 
                  regions={userRegionsData} 
                  isUserDashboard={true} 
                  firstRegionId={
                    (userRegionsData && userRegionsData.length > 0) ? userRegionsData[0].id : null
                  }  
                  title={"umsatz"} 
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <UmsatzRegion
                  regions={userRegionsData} 
                  isUserDashboard={true} 
                  firstRegionId={
                    (userRegionsData && userRegionsData.length > 0) ? userRegionsData[0].id : null
                  }  
                  title={"umsatzFertiggestellt"}
                />
              </Grid>
              {
                !loadingUmsatzeAndFertiggestellteRegions && 
                <Grid item xs={12} lg={12}>
                  <TopKunden
                    regions={UmsatzeAndFertiggestellteRegionsData} 
                    isUserDashboard={true} 
                    firstRegionId={
                      (UmsatzeAndFertiggestellteRegionsData && UmsatzeAndFertiggestellteRegionsData.length > 0) ? UmsatzeAndFertiggestellteRegionsData[0].id : null
                    }   
                  />
                </Grid>
              }
              <Grid item xs={12} lg={4}>
                <NochNichtAbgerechnet 
                  regions={userRegionsData} 
                  isUserDashboard={true} 
                  firstRegionId={
                    (userRegionsData && userRegionsData.length > 0) ? userRegionsData[0].id : null
                  }  
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <MonthlyPerformanceWidget />
              </Grid>
              <Grid item xs={12} lg={12}>
                <RevenuePerCustomerWidget />
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  }
};

export default Dashboard;
