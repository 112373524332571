import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import config from "./config";
import jsonexport from "jsonexport/dist";

const apiUrl = config.apiUrl;
const httpClient = fetchUtils.fetchJson;

const formJson = (items) => {
  const form_data = new FormData();
  for (let key in items) {
    if (typeof items[key] === "object" && items[key] !== null) {
      form_data.append(key, JSON.stringify(items[key]));
    } else {
      form_data.append(key, items[key]);
    }
  }
  return form_data;
};

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    //console.log(params.pagination);
    let query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    if(resource.startsWith('export-')){
      let sections = resource.split('-');
      resource = sections[1];
      query = {
        sort: JSON.stringify([field, order]),
        filter: JSON.stringify(params.filter),
      };
    }
    const url = `${apiUrl}?section=${resource}&${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  getOne: async (resource, params) =>
    httpClient(`${apiUrl}?section=${resource}&action=get&id=${params.id}`).then(
      ({ json }) => {
        return { data: json };
      }
    ),

  getMany: async (resource, params) => {
    const url = `${apiUrl}?section=${resource}&action=gets&ids=${JSON.stringify(
      {
        id: params.ids,
      }
    )}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
      listreq: "yes",
    };
    const url = `${apiUrl}?section=${resource}&${JSON.stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: async (resource, params) =>
    httpClient(`${apiUrl}?section=${resource}&action=update&id=${params.id}`, {
      method: "POST",
      body: formJson(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: async (resource, params) => {
    return httpClient(
      `${apiUrl}?section=${resource}&action=updatem&ids=${JSON.stringify({
        id: params.ids,
      })}`,
      {
        method: "POST",
        body: formJson(params.data),
      }
    ).then(({ json }) => ({ data: json }));
  },

  create: async (resource, params) =>
    httpClient(`${apiUrl}?section=${resource}&action=update`, {
      method: "POST",
      body: formJson(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: async (resource, params) =>
    httpClient(
      `${apiUrl}?section=${resource}&action=delete&ids=${JSON.stringify({
        id: [params.id],
      })}`,
      {
        method: "GET",
      }
    ).then(({ json }) => ({ data: json })),

  deleteMany: async (resource, params) => {
    return httpClient(
      `${apiUrl}?section=${resource}&action=delete&ids=${JSON.stringify({
        id: params.ids,
      })}`,
      {
        method: "GET",
      }
    ).then(({ json }) => ({ data: json }));
  },
};
export const handleExport = (resource, dataProvider, context) => {
    const { filterValues, sort } = context;
    dataProvider
        .getList(`export-${resource}`, {
            filter: filterValues,
            sort,
            pagination: { page: 1, perPage: -1 },
        })
        .then(({ data }) => {
            jsonexport(data, function (err, csv) {
                if (err) return console.error(err);
                const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" }); // Adjust type for other formats
                const link = document.createElement("a");
                const url = URL.createObjectURL(blob);
                link.href = url;
                link.setAttribute("download", `${resource}-export.csv`); // Adjust filename for other formats
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        });
};
export default dataProvider;
