import React, { createContext, useContext, useState } from "react";

const ContactContext = createContext();

export const useContacts = () => {
    return useContext(ContactContext);
};

export const ContactProvider = ({ children }) => {
    const [contacts, setContacts] = useState([
        {
            id: Date.now(),
            emails: [{email: '', id: Date.now(), contact_person_id: null}],
            phoneNumbers: [
                {
                    id: Date.now(),
                    phone_number_type: "",
                    countryCode: "49",
                    phoneNumber: "",
                    flagUrl: "https://flagcdn.com/w20/de.png",
                    flagName: "Germany",
                    countryCodeLabel: "DE",
                },
            ],
            salutation: "",
            firstName: "",
            lastName: "",
        },
    ]);
    const [copySuccess, setCopySuccess] = useState("");
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const addContact = () => {
        let contactPerson = {
            id: Date.now(),
            emails: [{email: '', id: Date.now(), contact_person_id: null}],
            phoneNumbers: [
                {
                    id: Date.now(),
                    phone_number_type: "",
                    countryCode: "49",
                    phoneNumber: "",
                    flagUrl: "https://flagcdn.com/w20/de.png",
                    flagName: "Germany",
                    countryCodeLabel: "DE",
                },
            ],
            salutation: "",
            firstName: "",
            lastName: "",
        };
        setContacts([
            ...contacts, contactPerson,
        ]);
        return contactPerson;
    };
    const addContactNumber = (contactPersonId) => {
        let contactNumber = {
            id: Date.now(),  
            phone_number_type: "",
            countryCode: "49",
            phoneNumber: "",
            flagUrl: "https://flagcdn.com/w20/de.png",
            flagName: "Germany",
            countryCodeLabel: "DE",
        };
        setContacts((prevContacts) =>
            prevContacts.map((contact, index) =>
                contact.id === contactPersonId
                    ? { ...contact, phoneNumbers: [...contact.phoneNumbers, contactNumber],} : contact
            )
        );
        return contactNumber;
    };
    const addContactEmail = (contactPersonId) => {
        let contactEmail = { email: '', id: Date.now(), contact_person_id: contactPersonId};
        setContacts((prevContacts) => {
            return prevContacts.map((contact, index) =>
                contact.id === contactPersonId
                    ? {
                          ...contact,
                          emails: [...contact.emails, contactEmail],
                      }
                    : contact
            );
        });
        return contactEmail;
    };
    const updateContactNumberField = (contactIndex, phoneNumberIndex, field, value) => {
        setContacts((prevContacts) => {
            // Create a copy of contacts array
            const updatedContacts = prevContacts.map((contact, i) => {
                if (i === contactIndex) {
                    // Create a copy of the contact object
                    const updatedContact = { ...contact };
                    
                    // Update phoneNumbers immutably
                    updatedContact.phoneNumbers = updatedContact.phoneNumbers.map((phone, j) => {
                        if (j === phoneNumberIndex) {
                            return { ...phone, [field]: value }; // Update the specific field
                        }
                        return phone;
                    });

                    return updatedContact;
                }
                return contact;
            });

            return updatedContacts; // Return the updated array
        });
    };
    const updateEmailField = (contactIndex, emailIndex, value) => {
        setContacts((prevContacts) => {
            // Create a copy of the contacts array
            const updatedContacts = prevContacts.map((contact, i) => {
                if (i === contactIndex) {
                    // Create a copy of the contact object
                    const updatedContact = { ...contact };
    
                    // Update the specific email immutably
                    updatedContact.emails = updatedContact.emails.map((email, j) => {
                        if (j === emailIndex) {
                            return {id: email.id, email: value, contact_person_id: email.contact_person_id};
                        }
                        return email;
                    });
    
                    return updatedContact;
                }
                return contact;
            });
    
            return updatedContacts; // Return the updated array
        });
    };
    
    const updateContactField = (index, field, value) => {
        setContacts((prevContacts) => prevContacts.map((contact, i) => (i === index ? { ...contact, [field]: value } : contact)));
    };
    const deleteContact = (index) => {
        if (contacts.length > 1) {
            setContacts((prevContacts) =>
                prevContacts.filter((_, i) => i !== index)
            );
        }
    };

    const deletePhoneNumber = (contactIndex, phoneNumberIndex) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          phoneNumbers: contact.phoneNumbers.filter((_, i) => i !== phoneNumberIndex),
                      }
                    : contact
            )
        );
    };
    

    const deleteEmail = (contactIndex, emailIndex) => {
        setContacts((prevContacts) =>
            prevContacts.map((contact, index) =>
                index === contactIndex
                    ? {
                          ...contact,
                          emails: contact.emails.filter((_, i) => i !== emailIndex),
                      }
                    : contact
            )
        );
    };
    

    return (
        <ContactContext.Provider
            value={{
                contacts,
                setContacts,
                addContact,
                addContactNumber,
                addContactEmail,
                updateContactField,
                updateEmailField,
                updateContactNumberField,
                deleteContact,
                deletePhoneNumber,
                deleteEmail,
                copySuccess,
                setCopySuccess,
                isSnackbarOpen,
                setSnackbarOpen,
            }}
        >
            {children}
        </ContactContext.Provider>
    );
};
