export const doFlex = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: 0,
};

export const labelChoices = [
  { id: "work", name: "Büro" },
  { id: "work_extension", name: "Durchwahl" },
  { id: "mobile", name: "Mobil" },
  { id: "home", name: "Privat" },
];

export const titleChoices = [
  { id: "Frau", name: "Frau" },
  { id: "Herr", name: "Herr" },
];
