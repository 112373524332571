import * as React from "react";
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  PasswordInput,
  UrlField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  FormDataConsumer,
  Create,
  required,
  CloneButton,
  ReferenceInput,
  ReferenceField,
  NumberInput,
  RecordContextProvider,
  DatagridBody,
  DateField,
  DateInput,
  Pagination,
  ListButton,
  TopToolbar,
  useNotify,
  useRedirect,
  SaveButton,
  ShowButton,
  EditableDatagrid,
  RowForm,
  DeleteButton,
  Toolbar,
  usePermissions,
  useRecordContext,
  useUpdate,
  AutocompleteInput,
  DeleteWithConfirmButton,
  BulkDeleteButton,
  AutocompleteArrayInput,
  SimpleFormIterator,
  ArrayInput,
  useCreatePath,
  WrapperField,
  ReferenceArrayInput,
  TabbedShowLayout,
  useGetOne,
  FunctionField,
  useListController,
} from "react-admin";
import { Link } from "react-router-dom";
import {
  TableCell,
  TableRow,
  Checkbox,
  Chip,
  Stack,
  TextField as TextInputCustom,
  Box,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import WarningIcon from "@mui/icons-material/Warning";
import InputAdornment from "@mui/material/InputAdornment";
import AlertTitle from "@mui/material/AlertTitle";
import { useFormContext } from "react-hook-form";
import { AdminField, AkLinkField, AkMultiLinkField } from "./AdminField";
import MyEditButton from "./MyEditButton";
import ActionInline from "./ActionInline";
import BulkProjectEdit from "./BulkProjectEdit";
import { CreateWorkers } from "./Workers";
import PaletteIcon from "@mui/icons-material/Palette";
import FileList from "./FileList";
import config from "../config";
import dataProvider, { handleExport } from "../dataProvider";
const colorsArr = [
  "#fff",
  "#C80002",
  "#FFD600",
  "#525252",
  "#CCCCCC",
  "#00AEEF",
  "#7925C7",
  "#F75C1E",
  "#92C83E",
  "#F766AE",
];

const ProjectEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton label="< Zurück" />
  </TopToolbar>
);

const MyDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => (
  <RecordContextProvider value={record}>
    <TableRow>
      {/* first column: selection checkbox */}
      <TableCell padding="none">
        <Checkbox
          //disabled={selectable}
          checked={selected}
          onClick={(event) => onToggleItem(id, event)}
        />
      </TableCell>
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell
          key={`${id}-${field.props.source}`}
          style={
            typeof field.props.source !== "undefined" &&
            field.props.source !== "u_access"
              ? {
                  background: colorsArr[record.farbe],
                  color:
                    record.farbe == 2 || record.farbe == 4 || record.farbe == 9
                      ? "rgba(0, 0, 0, 0.87)"
                      : "#fff",
                  width: field.props.source == "adresse" ? "15%" : "auto",
                }
              : {}
          }
          onClick={() => {
            const tmpOld = document.getElementsByClassName("akInlineField");
            for (var i = 0; i < tmpOld.length; i++) {
              tmpOld[i].style.display = "none";
            }
            const tmpNow = document.getElementById(
              `input-${id}-${field.props.source}`
            );
            if (tmpNow) {
              tmpNow.style.display = "block";
            }
          }}
        >
          {field.props.source == "action_date" ||
          field.props.source == "action" ||
          field.props.source == "farbe" ? (
            <ActionInline field={field} record={record} id={id} />
          ) : (
            ""
          )}
          {field.props.source == "farbe" ? <PaletteIcon /> : field}
        </TableCell>
      ))}
    </TableRow>
  </RecordContextProvider>
);
const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const ProjecttBulkActionButtons = () => (
  <div className="projects-bulk">
    <BulkProjectEdit />
    {/*<BulkDeleteButton mutationMode="pessimistic" />*/}
  </div>
);
const MyDatagrid = (props) => (
  <Datagrid
    {...props}
    body={<MyDatagridBody />}
    bulkActionButtons={<ProjecttBulkActionButtons />}
  />
);

const ProjectPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);

const ProjectRowTools = ({ source }) => {
  const { permissions } = usePermissions();
  const record = useRecordContext();
  return (
    <>
      {permissions.access === "super" ||
      permissions.access === "admin" ||
      (permissions.access === "user" && record[source] == 2) ? (
        <>
          <CloneButton label="" />
        </>
      ) : null}
    </>
  );
};

export const ProjectList = () => {
  const listContext = useListController();
  const myExporter = (resources) => {
    handleExport('projects', dataProvider, listContext);
  }
  return (
    <List
      className="projects-grid"
      filters={projectFilters}
      perPage={50}
      pagination={<ProjectPagination />}
      sort={{ field: "action_date", order: "ASC" }}
      exporter={myExporter}
    >
      <MyDatagrid>
        <TextField source="id" label="Tr.-Nr" />
        <TextField source="farbe" label="Farbe" />
        {/* <ReferenceField
          source="userId"
          reference="users"
          label="Verantwortlicher" 
        >
          <TextField source="name" />
        </ReferenceField> */}
        <AkLinkField source="u_" res="users" label="Verantwortlicher" />
        <DateField source="action_date" label="Action Date" />
        <TextField source="action" label="Action" />
        <TextField source="gewerk" label="Gewerk" />
        {/* <TextField source="kunde" label="Kunde" /> */}
        <TextField source="adresse" label="Adresse" />
        <DateField source="auftragseingang" label="Auftrags- eingang" />
        <DateField source="finish_date" label="Fertigst- ellung" />
        {/* <ReferenceField
          source="zustandig"
          label="Zuständig"
          reference="workers"
        >
          <TextField source="name" />
        </ReferenceField> */}
        <AkLinkField source="k_" res="kundenlistes" label="Kunde" />
        <AkMultiLinkField source="z_" res="workers" label="Zuständig" />
        <TextField source="deskunden" label="Rechnungsnr" />
        <TextField source="agnr" label="AG-Nr" />
        <EditButton label="" />
        <ProjectRowTools source="u_access" label="" />
      </MyDatagrid>
    </List>
  );
};

const PostEditToolbar = ({ srcFrom }) => {
  const [update] = useUpdate();
  const { getValues } = useFormContext();
  const redirect = useRedirect();
  const handleClick = (e) => {
    e.preventDefault(); // necessary to prevent default SaveButton submit logic
    const { id, ...data } = getValues();
    update(
      "projects",
      { id, data: { id, ...data } },
      {
        onSuccess: () => {
          //redirect(`/projects?filter={"regionId":"${data.regionId}"}`);
          redirect(`/projects`);
        },
      }
    );
  };
  return (
    <Toolbar>
      <SaveButton alwaysEnable />
      &nbsp;
      <SaveButton
        alwaysEnable
        label="Save and Go"
        type="button"
        onClick={handleClick}
      />
      {/*srcFrom !== "new" && <DeleteWithConfirmButton />*/}
    </Toolbar>
  );
};

export const ProjectEdit = (props) => (
  <Edit title="Edit Project" actions={<ProjectEditActions />}>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="Details">
        <SimpleForm toolbar={<PostEditToolbar srcFrom="edit" />}>
          <TextInput readOnly source="id" />
          <ReferenceInput
            source="userId"
            reference="users"
            // validate={required()}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput source="regionId" label="Region" reference="regions">
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput source="kundeId" label="Kunde" reference="kundenlistes">
            <AutocompleteInput 
              fullWidth 
              optionText="name"
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                <>
                  {formData.kundeId && formData.kundeId !== 0 && (
                    <SpecialRequestsInput key={formData.kundeId} kundeId={formData.kundeId} />
                  )}
                </>
              );
            }}
          </FormDataConsumer>
          <TextInput
            label="Email-Adresse des Kunden + Name des Verwalters"
            source="emailAndAdministrator"
            multiline
            fullWidth
          />
          <DateInput
            label="Action Date"
            source="action_date"
            validate={required()}
          />
          <TextInput
            label="Action"
            source="action"
            multiline
            fullWidth
            validate={required()}
          />
          <SelectInput
            validate={required()}
            source="gewerk"
            label="Gewerk"
            choices={config.gewerkChoices}
          />
          {/* <TextInput
          multiline
          fullWidth
          label="Kunde"
          source="kunde"
          validate={required()}
        /> */}
          <TextInput
            multiline
            fullWidth
            label="Adresse"
            source="adresse"
            validate={required()}
          />
          <DateInput
            label="Auftragseingang"
            source="auftragseingang"
            validate={required()}
          />
          <DateInput
            label="Fertigstellung"
            source="finish_date"
            validate={required()}
          />
          <ArrayInput source="zuts" label="Zuständig">
            <SimpleFormIterator inline>
                <ReferenceInput
                  source="zustandig"
                  reference="workers"
                  // validate={required()}
                >
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
                <NumberInput
                  source="umsatz"
                  helperText={false}
                  validate={required()}
                  InputProps={{
                    sx: { padding: '8px 0', display: 'flex', alignItems: 'center', marginBottom: '22px' } 
                  }}
                />
                <NumberInput
                  source="deckungsbeitrag"
                  helperText={false}
                  validate={required()}
                  InputProps={{
                    sx: { padding: '8px 0', marginBottom: '22px'} 
                  }}
                />
                <SelectInput
                  source="schulnote"
                  choices={[
                    { id: 1, name: "1" },
                    { id: 2, name: "2" },
                    { id: 3, name: "3" },
                    { id: 4, name: "4" },
                    { id: 5, name: "5" },
                    { id: 6, name: "6" },
                  ]}
                  InputProps={{
                    sx: { padding: '8px 0' } 
                  }}
                />
                <ReferenceArrayInput source="beschwerde" reference="beschwerde">
                  <AutocompleteArrayInput optionText="name" />
                </ReferenceArrayInput>
                <TextInput label="Kommentar" source="kommentar" InputProps={{
                    sx: { padding: '8px 0' } 
                  }}/>
            </SimpleFormIterator>
          </ArrayInput>
          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            <NumberInput source="facharbeiter" helperText={false} />
            <NumberInput source="tage" helperText={false} />
            <NumberInput source="stunden" helperText={false} />
          </Stack>
          <ArrayInput source="taskers" label="Taskers">
            <SimpleFormIterator inline>
              <ReferenceInput source="workId" reference="works">
                <AutocompleteInput
                  optionText="name"
                  label="Auf die Liste setzen von"
                  validate={required()}
                />
              </ReferenceInput>
              <DateInput
                label="Dringlichkeit"
                source="dringlichkeit"
                validate={required()}
              />
              <TextInput
                label="Kommentar"
                source="task_kommentar"
                validate={required()}
              />
              <TextInput
                label="Von wem"
                source="vonwem"
                validate={required()}
              />
              <TextInput
                label="Weitere Infos"
                source="task_infos"
                validate={required()}
              />
              <SelectInput
                validate={required()}
                source="farbe"
                label="Farbe (color)"
                sx={{ display: "none" }}
                defaultValue={1}
                choices={[
                  { id: 1, name: " . . . . . . . . . . " },
                  { id: 2, name: "." },
                  { id: 3, name: "." },
                  { id: 4, name: "." },
                  { id: 5, name: "." },
                  { id: 6, name: "." },
                  { id: 7, name: "." },
                  { id: 8, name: "." },
                  { id: 9, name: "." },
                ]}
              />
              <ReferenceInput source="zustandig" reference="workers">
                <AutocompleteInput optionText="name" validate={required()} />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
          <SelectInput
            validate={required()}
            source="farbe"
            label="Farbe (color)"
            className="list_farbe"
            choices={[
              { id: 1, name: " . . . . . . . . . . " },
              { id: 2, name: "." },
              { id: 3, name: "." },
              { id: 4, name: "." },
              { id: 5, name: "." },
              { id: 6, name: "." },
              { id: 7, name: "." },
              { id: 8, name: "." },
              { id: 9, name: "." },
            ]}
          />
          <TextInput
            multiline
            label="Kontaktdaten Betroffene Personen"
            source="personen"
            fullWidth
          />
          <TextInput multiline fullWidth label="Verlauf" source="verlauf" />
          <TextInput
            multiline
            fullWidth
            label="Weitere Informationen"
            source="informationen"
          />
          <TextInput
            multiline
            fullWidth
            label="Subunternehmer"
            source="subunternehmer"
          />
          <TextInput
            fullWidth
            label="AG-Nr"
            source="agnr"
            validate={required()}
          />
          <TextInput
            validate={required()}
            label="Rechnungsanschrift"
            source="rechnungsanschrift"
            fullWidth
          />
          <TextInput
            label="Rechnung weitere Informationen"
            source="rechnung_weitere_info"
            multiline
            fullWidth
          />

          <TextInput
            label="Spezielle
Rechnungsanforderungen"
            source="note_for_invoicing"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    backgroundColor: "lightyellow",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  <WarningIcon style={{ color: "#ed6c02", fontSize: "20px" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "lightyellow", // Apply lightyellow background to input field container
                "& fieldset": {
                  border: "1px solid #dbc904", // Yellow border
                },
                "&:hover fieldset": {
                  border: "1px solid #dbc904", // Yellow border on hover
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #dbc904", // Yellow border on focus
                },
              },
              "& .MuiOutlinedInput-input": {
                backgroundColor: "#fffde1", // Light yellow background for input field content
              },
              "& .MuiFormLabel-root": {
                color: "#666666", // Gray label by default
                fontSize: "1rem",
              },
              "& .Mui-focused .MuiFormLabel-root": {
                color: "#666666", // Gray label when focused
              },
            }}
          />

          <ReferenceInput reference="users" source="abgerechnet_geschriebenId">
            <AutocompleteInput
              optionText="name"
              label="Angebot geschrieben von"
              sx={{ minWidth: "300px" }}
            />
          </ReferenceInput>
          <ReferenceInput
            label="Abgerechnet von"
            reference="users"
            source="abgerechnet_vonId"
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <TextInput
            label="Externe Auftragsnummer"
            source="externe_auftragsnummer"
            fullWidth
            multiline
          />
          <TextInput
            label="Unsere Rechnungsnummer"
            source="deskunden"
            fullWidth
            multiline
          />
          <TextInput readOnly source="completed_at" variant="outlined" />
        </SimpleForm>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Files">
        <FileList />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Edit>
);
export const ProjectCreate = (props) => {
  return (
    <Create title="Add New Project" {...props} actions={<ProjectEditActions />}>
      <SimpleForm redirect="list" toolbar={<PostEditToolbar srcFrom="new" />}>
        <ReferenceInput
          source="userId"
          reference="users"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="regionId"
          reference="regions"
          filter={{ can: 1 }}
        />
        <ReferenceInput
          source="kundeId"
          label="Kunde"
          reference="kundenlistes"
        >
          <AutocompleteInput fullWidth optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                {formData.kundeId && formData.kundeId !== 0 && (
                  <SpecialRequestsInput kundeId={formData.kundeId} />
                )}
              </>
            );
          }}
        </FormDataConsumer>
        <TextInput
          label="Email-Adresse des Kunden + Name des Verwalters"
          source="emailAndAdministrator"
          multiline
          fullWidth
        />
        <DateInput
          label="Action Date"
          source="action_date"
          validate={required()}
        />
        <TextInput
          label="Action"
          source="action"
          multiline
          fullWidth
          validate={required()}
        />
        <SelectInput
          validate={required()}
          source="gewerk"
          label="Gewerk"
          choices={config.gewerkChoices}
        />
        {/* <TextInput
          multiline
          fullWidth
          label="Kunde"
          source="kunde"
          validate={required()}
        /> */}
        <TextInput
          multiline
          fullWidth
          label="Adresse"
          source="adresse"
          validate={required()}
        />
        <DateInput
          label="Auftragseingang"
          source="auftragseingang"
          validate={required()}
          defaultValue={new Date().toISOString().substr(0, 10)}
        />
        <DateInput
          label="Fertigstellung"
          source="finish_date"
          validate={required()}
        />
        <ArrayInput source="zuts" label="Zuständig">
          <SimpleFormIterator inline>
            <ReferenceInput
              source="zustandig"
              reference="workers"
              // validate={required()}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput
              source="umsatz"
              helperText={false}
              validate={required()}
            />
            <NumberInput
              source="deckungsbeitrag"
              helperText={false}
              validate={required()}
            />
            <SelectInput
              source="schulnote"
              label="Schulnote"
              choices={[
                { id: 1, name: "1" },
                { id: 2, name: "2" },
                { id: 3, name: "3" },
                { id: 4, name: "4" },
                { id: 5, name: "5" },
                { id: 6, name: "6" },
              ]}
            />
            <ReferenceArrayInput source="beschwerde" reference="beschwerde">
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
            <TextInput label="Kommentar" source="kommentar" />
          </SimpleFormIterator>
        </ArrayInput>
        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
          <NumberInput source="facharbeiter" helperText={false} />
          <NumberInput source="tage" helperText={false} />
          <NumberInput source="stunden" helperText={false} />
        </Stack>
        <ArrayInput source="taskers" label="Taskers">
          <SimpleFormIterator inline>
            <ReferenceInput source="workId" reference="works">
              <AutocompleteInput
                optionText="name"
                label="Auf die Liste setzen von"
                validate={required()}
              />
            </ReferenceInput>
            <DateInput
              label="Dringlichkeit"
              source="dringlichkeit"
              validate={required()}
            />
            <TextInput
              label="Kommentar"
              source="task_kommentar"
              validate={required()}
            />
            <TextInput label="Von wem" source="vonwem" validate={required()} />
            <TextInput
              label="Weitere Infos"
              source="task_infos"
              validate={required()}
            />
            <SelectInput
              validate={required()}
              source="farbe"
              label="Farbe (color)"
              sx={{ display: "none" }}
              defaultValue={1}
              choices={[
                { id: 1, name: " . . . . . . . . . . " },
                { id: 2, name: "." },
                { id: 3, name: "." },
                { id: 4, name: "." },
                { id: 5, name: "." },
                { id: 6, name: "." },
                { id: 7, name: "." },
                { id: 8, name: "." },
                { id: 9, name: "." },
              ]}
            />
            <ReferenceInput source="zustandig" reference="workers">
              <AutocompleteInput optionText="name" validate={required()} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        {/* <NumberInput label="Umsatz" source="umsatz" validate={required()} />
        <NumberInput
          label="Deckungsbeitrag"
          source="deckungsbeitrag"
          validate={required()}
        /> */}

        <SelectInput
          validate={required()}
          source="farbe"
          label="Farbe (color)"
          className="list_farbe"
          choices={[
            { id: 1, name: " . . . . . . . . . . " },
            { id: 2, name: "." },
            { id: 3, name: "." },
            { id: 4, name: "." },
            { id: 5, name: "." },
            { id: 6, name: "." },
            { id: 7, name: "." },
            { id: 8, name: "." },
            { id: 9, name: "." },
          ]}
        />
        <TextInput
          multiline
          fullWidth
          label="Kontaktdaten Betroffene Personen"
          source="personen"
        />
        <TextInput multiline fullWidth label="Verlauf" source="verlauf" />
        <TextInput
          multiline
          fullWidth
          label="Weitere Informationen"
          source="informationen"
        />
        <TextInput
          multiline
          fullWidth
          label="Subunternehmer"
          source="subunternehmer"
        />
        <TextInput
          fullWidth
          label="AG-Nr"
          source="agnr"
          validate={required()}
        />
        <TextInput
          validate={required()}
          label="Rechnungsanschrift"
          source="rechnungsanschrift"
          fullWidth
        />
        <TextInput
          label="Rechnung weitere Informationen"
          source="rechnung_weitere_info"
          multiline
          fullWidth
        />
        <TextInput
          label="Spezielle
Rechnungsanforderungen"
          source="note_for_invoicing"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  backgroundColor: "lightyellow",
                  padding: "4px",
                  borderRadius: "4px",
                }}
              >
                <WarningIcon style={{ color: "#ed6c02", fontSize: "20px" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "lightyellow", // Apply lightyellow background to input field container
              "& fieldset": {
                border: "1px solid #dbc904", // Yellow border
              },
              "&:hover fieldset": {
                border: "1px solid #dbc904", // Yellow border on hover
              },
              "&.Mui-focused fieldset": {
                border: "1px solid #dbc904", // Yellow border on focus
              },
            },
            "& .MuiOutlinedInput-input": {
              backgroundColor: "#fffde1", // Light yellow background for input field content
            },
            "& .MuiFormLabel-root": {
              color: "#666666", // Gray label by default
              // fontSize: "1rem",
            },
            "& .Mui-focused .MuiFormLabel-root": {
              color: "#666666", // Gray label when focused
            },
          }}
        />
        <ReferenceInput reference="users" source="abgerechnet_geschriebenId">
          <AutocompleteInput
            optionText="name"
            label="Angebot geschrieben von"
            sx={{ minWidth: "300px" }}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Abgerechnet von"
          reference="users"
          source="abgerechnet_vonId"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput
          label="Externe Auftragsnummer"
          source="externe_auftragsnummer"
          fullWidth
          multiline
        />
        <TextInput
          label="Unsere Rechnungsnummer"
          source="deskunden"
          fullWidth
          multiline
        />
      </SimpleForm>
    </Create>
  );
};

const SpecialRequestsInput = ({ kundeId }) => {
  const { data, isLoading } = useGetOne("kundenlistes", { id: kundeId });
  if (isLoading) {
    return <div style={{ display: "none" }}>Loading...</div>;
  }
  if (data && data.special_request) { 
    return (
      <Alert
        icon={<WarningIcon fontSize="inherit" />}
        severity="warning"
        sx={{
          width: "100%",
          marginBottom: "20px",
          marginTop: "-20px",
          backgroundColor: "#fffde1",
          border: "1px solid #b7b71c",
        }}
      >
        <span style={{ color: "#584403" }}>
          {data.special_request ?? ''}
        </span>
      </Alert>
    );
  }
  return null;
};

const QuickFilter = ({ label }) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};

const projectFilters = [
  <TextInput 
    label="Search" 
    source="q" alwaysOn
    sx={{
      "& .MuiInputLabel-root": {
       top: "8px",
     },
    }} 
    InputProps={{
      sx: { padding: '8px 0', display: 'flex', alignItems: 'center' } 
    }}
  />,
  <ReferenceArrayInput
    source="zustandig"
    label="Zuständig"
    reference="workers"
    alwaysOn
  />,
  <ReferenceInput source="kundeId" label="Kunde" reference="kundenlistes" alwaysOn>
    <AutocompleteInput 
      optionText="name"
      fullWidth 
    />
  </ReferenceInput>,
  <ReferenceInput source="cregions" label="Regions" reference="regions" alwaysOn sx={{padding: '8px 0', display: 'flex', alignItems: 'center', marginBottom: '-4px' }}>
    <AutocompleteArrayInput optionText='name' label="Regions"/>
  </ReferenceInput>,
  <AutocompleteArrayInput
    source="farbe"
    alwaysOn
    label="Farbe (color)"
    className="project-color-filter"
    sx={{
      '& .MuiInputBase-root': {
        paddingTop: '22px !important',    
      },
    }} 
    choices={[
      { id: 0, name: <span className="farbess farbe0">.</span> },
      { id: 1, name: <span className="farbess farbe1">.</span> },
      { id: 2, name: <span className="farbess farbe2">.</span> },
      { id: 3, name: <span className="farbess farbe3">.</span> },
      { id: 4, name: <span className="farbess farbe4">.</span> },
      { id: 5, name: <span className="farbess farbe5">.</span> },
      { id: 6, name: <span className="farbess farbe6">.</span> },
      { id: 7, name: <span className="farbess farbe7">.</span> },
      { id: 8, name: <span className="farbess farbe8">.</span> },
      { id: 9, name: <span className="farbess farbe9">.</span> },
    ]}
  />,
  <ReferenceInput
    label="Abgerechnet von"
    reference="users"
    source="abgerechnet_vonId"
  />,
  <SelectInput
    source="gewerk"
    label="Gewerk"
    alwaysOn
    InputProps={{
      sx: { padding: '8px 0', display: 'flex', alignItems: 'center', marginBottom: '-4px' } 
    }}
    choices={config.gewerkChoices}
  />,
  // <ReferenceInput
  //   label="Projekte von"
  //   reference="users"
  //   source="responsible_person_id"
  // />,
  <ReferenceInput
    key="id"
    reference="users"
    source="responsible_person_id"
    label="Projekte von"
  >
    <SelectInput optionText="name" label="Projekte von" />
  </ReferenceInput>,
  <QuickFilter source="mine" label="Meine Projekte" defaultValue={"yes"} />,
  // <QuickFilter
  //   source="responsible_person"
  //   label="Verantwortliche"
  //   defaultValue={"yes"}
  // />,
];
